html,
body{
    height: 100%;
}

body{
    font-size: 14px;
    width: 100%;
    font-family:$mainFont;
    color:$mainColor;
    -webkit-font-smoothing: antialiased;
    background:$bodyBackground;
    user-select: none;

    @if $customScroll == true {
        @media (min-width: 768px) {
            height: 100vh;
            overflow: hidden;
            .main-page-holder{
                position: relative;
                width: 100%;
                height: 100vh;
            }

        }

    }
}

a {
    text-decoration:none;
    color:#fff;
}
a:hover {
    text-decoration: none;
}
b,strong,h1,h2,h3,h4,h5{
    font-weight:500;
}
body{
    ._gCustomScroll,
    ._gCustomScrollNOXS,
    .selectric-scroll{
        .scrollbar-track-y{
            width: 3px !important;
            opacity: 1;
            border-radius: 2px;
            overflow: hidden;
            background: #555 !important;
            .scrollbar-thumb{
                width: 4px !important;
                border-radius: 2px;
                background: #ccc !important;

                &:after {
                    display: none;
                }
            }
        }
        &.hasScroll{
            padding-right: 25px;
        }
    }
}

._bg-full{
    background-position: center center;
    background-size: cover !important;
}
.abs-link{
    @include full-height-abs;
    z-index: 3;
    display: block;
    overflow: hidden;
    text-indent: -9999px;
}
button{
    outline: none;
    cursor: pointer;
    background: none;
    padding: 0;
    border: none;
    display: inline-block;
}

body {
    div.scrollbar-track-y {
        background-color: transparent !important;
        width: 25px !important;
        z-index: 50 !important;

        .scrollbar-thumb {
            background: none !important;
            border-radius: 30px;
            bottom: 0px;
            left: 0;
            top: 0px;
            width: 100%;

            &:hover{
                &:after {
                    background:#666;
                }
            }
            &:after {
                @include after-box;
                background-color: #ddd;
                border-radius: 3px;
                bottom: 0;
                left: 50%;
                position: absolute;
                top: 0;
                width: 6px;
                transition:background-color 0.3s ease;
            }

            &:hover {
                background-color: #ccc;
            }
        }
    }
}
img.full-width{
    width:100%;
}
.all-page-wrapper{
    width:100%;
    overflow:hidden;
    display:flex;
    flex-direction:column;
    flex-wrap:nowrap;
    justify-content:flex-start;
    align-items:stretch;
    min-height:100vh;
    .all-page-holder{
        flex:1;
        display:flex;
        flex-direction:column;
        flex-wrap:nowrap;
        justify-content:flex-start;
        align-items:stretch;
    }
}
._g-plefted{
    @media #{$media-xlg}{
        padding: 0 30px;
    }
}
.rel-box{
    position:relative;
    z-index:3;
}

._g-red-btn {
    background:$redColor;
    display:flex;
    flex-direction:row;
    flex-wrap:nowrap;
    justify-content:center;
    align-items:center;
    text-align:center;
    color:#ffffff;
    font-weight:500;
    font-size:21px;
    height:58px;
    transition:all 0.3s ease;
    border-radius:10px;
    padding: 0 20px;
    &:hover{
        background:#000;
    }
}
.global-close-btn{
    position: absolute;
    right: 50px;
    top: 50px;
    width: 20px;
    height: 20px;
    background: none;
    outline: none;
    border: none;
    z-index: 5;
    @media #{$media-xs}{
        top: 40px;
        right: 30px;
    }
    &:before,
    &:after{
        @include after-box;
        height: 2px;
        background-color: #000;
        transform: rotate(45deg);
        position: absolute;
        left: 0;
        width: 135%;
        top: 0;
        margin-top: 8px;
        margin-left: -3px;
        transition: all 0.3s ease;

    }
    &:after{
        transform: rotate(-45deg);
    }
    &:hover{
        &:before,
        &:after{
            background:#ccc;
        }
    }
}
