header{
	position:absolute;
	top:5vh;
	left:0;
	width:100%;
	z-index:150;
	transition:background-color 0.3s ease;
	@media #{$media-sm}{
		top:4vh;
	}
	@media #{$media-xs}{
		top:0;
		.container{
			padding: 0;
		}
	}
	.header-flex{
		display:flex;
		flex-direction:row;
		flex-wrap:nowrap;
		justify-content:space-between;
		align-items:stretch;
		padding: 15px 0;
		border-bottom:1px solid rgba(255, 255, 255, 0.5);
		transition:all 0.3s ease;
		@media #{$media-xs}{
			padding: 0;
			border:none;
			display:block;
		}
	}
	.logo{
		display:block;
		position:relative;
		margin-top:-13px;
		transition:all 0.3s ease;
		@media #{$media-xs}{
			margin-top:-10px;
		}
		span{
			font-size:1.8vw;
			font-weight:300;
			display:block;
			@media #{$media-xs}{
				font-size:7vw;
				line-height:7vw;
			}
			@media #{$media-xxlg}{
				font-size:30px;
			}
			@media #{$media-sm}{
				font-size:2vw;
				line-height:2vw;
			}
			b{
				font-weight:bold;
				@media #{$media-sm}{
					display:block;
				}
				@media #{$media-xs}{
					display:block;
				}
			}
		}
		img{
			display:block;
			margin-bottom:-3px;
			@media #{$media-sm}{
				margin-bottom:2px;
			}
			@media #{$media-xs}{
				margin-top:0;
				margin-bottom:2px;
			}
		}
	}
	.menu-column-row{
		width:100%;
		@media #{$media-xs}{
			height:100%;
			display:flex;
			flex-direction:column;
			flex-wrap:nowrap;
			justify-content:space-between;
			align-items:center;
			padding-top:2vh;
			padding-bottom:5vh;
			nav {
				flex:1;
				width:100%;
				padding-top:15vh;
				padding-bottom:5vh;
			}
			._g-red-arrow-btn{
				height:60px;
				width:100%;
				text-align:center;
				justify-content: center;
				margin-bottom:2vh;
				span{
					width:100%;
					text-align:center;
				}
			}
			.xs-menu-bottom{
				width:100%;
				.mail-link {
					color:#000000;
					text-decoration:underline;
					font-weight:500;
					font-size:25px;
				}
				.adr{
					display:block;
					font-size:15px;
					line-height:15px;
					margin-top:10px;
				}
			}
		}

	}
	.left-column{
		padding-right:2vw;
		display:flex;
		flex-direction:column;
		flex-wrap:nowrap;
		justify-content:center;
		align-items:flex-start;
		@media (min-width:1500px) {
			padding-right:30px;
		}
		@media #{$media-xs}{
			width:100%;
			position:relative;
			flex-direction:row;
			justify-content:space-between;
			align-items:center;
			padding: 4vh 6vw 20px;
			z-index:10;
			transition:all 0.3s ease;
			&:before {
				@include after-box;
				position:absolute;
				top:100%;
				left:6vw;
				right:6vw;
				height:1px;
				background:#fff;
			}
		}
	}

	.menu-column {
		flex:1;
		padding:0 1vw 0 1vw;
		border-left:1px solid rgba(255, 255, 255, 0.4);
		transition:border-color 0.3s ease;
		@media (min-width:1500px) {
			padding-left:30px;
		}

		@media #{$media-xs}{
			display:none;
			position:absolute;
			background:#F6F6F7;
			top:0;
			left:0;
			width:100%;
			padding: 0 6vw;
			height:100vh;
		}


	}
	.main-nav{
		display:flex;
		flex-direction:row;
		flex-wrap:nowrap;
		justify-content:flex-start;
		align-items:center;
		width:100%;
		@media #{$media-sm}{
			justify-content:space-between;
		}
		@media #{$media-xs}{
			flex-direction:column;
			height:100%;
			align-items:stretch;
			justify-content:space-between;
		}
		li{
			a {
				font-size:16px;
				transition:all 0.3s ease;
				padding: 20px 1vw;
				white-space: nowrap;
				display:block;
				@media (min-width:1199px) and (max-width:1260px) {
					font-size:15px;
				}
				@media (min-width:1500px) {
					padding: 20px;
				}
				@media #{$media-xs}{
					padding: 5px 5%;
					color:#000;
					font-weight:500;
					font-size:9vw;
					line-height:9vw;
				}
				span{
					border-bottom:1px solid transparent;
					display:block;
					position:relative;

					&:after {
						@include after-box;
						position:absolute;
						margin-top:5px;
						transform:translateX(-50%);
						width:0;
						opacity:0;
						background:$redColor;
						height:1px;
						left:50%;
						top:100%;
						transition:all 0.3s ease;
					}
				}
				&:hover{

					@media (min-width:768px) {
						color:rgba(255, 255, 255, 0.69);
					}
				}
				&.actived{
					span{
						&:after {
							opacity:1;
							width:100%;
						}
					}
				}
			}
		}
	}
	.get-consult-btn {
		height:100%;
		display:flex;
		flex-direction:row;
		flex-wrap:nowrap;
		justify-content:center;
		align-items:center;
		padding:0 2vw;
		font-size:17px;
		line-height:19px;
		transition:all 0.3s ease;
		border-left:1px solid rgba(255,255,255,0.4);
		&:hover{
			background:$redColor;
			border-color:$redColor;
		}
	}
	.header-holder{
		transition:all 0.5s ease;

	}
	.right-column{
		transition:all 0.3s ease;
		@media #{$media-sm}{
			display:none;
		}
		@media #{$media-xs}{
			display:none;
		}
	}
}

header.actived{
	//transform:none !important;
	.header-holder{
		background:#fff;
		box-shadow:0 0 16px rgba(0, 0, 0, 0.06);
		transform:translateY(118%);
	}
	.header-flex{
		padding: 10px 0 5px;
	}
	.logo{
		color:#000;
	}
	.main-nav{
		justify-content:center;
		li{
			a{
				color:#000;
				&:hover{
					color:#666;
				}
			}
		}
	}
	.menu-column {
		border-color:#F2F2F2;
		padding-left:0;
	}
	.get-consult-btn{
		background:$redColor;
		margin-top:-10px;
		&:hover{
			background:#000;
		}
	}
	.right-column{
		margin-bottom:-17px;
	}
}

.mobile-menu-btn {
	z-index: 150;
	margin: -5px 0 0;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	width: 56px;
	height: 45px;
	position:relative;
	padding: 8px 12px;
	@media (min-width: 768px) {
		display: none;
	}
	span {
		border: none;
		width: 100%;
		height: 3px;
		background: none;
		position: relative;
		transition: all 0.3s ease;
		background: #fff;
		border-radius:2px;
		&:before,
		&:after {
			@include after-box;
			transition: all 0.3s ease;
			position: absolute;
			left: 0;
			width: 100%;
			height: 3px;
			background-color: #fff;
			box-sizing: inherit;
			border-radius:2px;
		}

		&:before {
			top: -10px;
			transition-property: top, transform;
		}

		&:after {
			bottom: -10px;
			transition-property: bottom, transform;
		}

	}

	em {
		display: inline-block;
		vertical-align: middle;
		margin-left: 25px;
		text-transform: uppercase;
		color: #fff;
		font-weight: 500;
		font-size: 15px;
		font-style: normal;

		@media #{$media-xs}{
			display: none;
		}
	}
	&.btn-actived{
		span{
			background:none;
			&:before {
				top: 0;
				transform: rotate(45deg);
			}

			&:after {
				bottom: 0;
				transform: rotate(-45deg);
				width: 100%;
			}

		}
	}
}
header.menu-opened{
	.left-column{
		background:#fff;
		padding-top:15px;
		padding-bottom:10px;
	}
	.logo{
		color:#000;
	}
	.mobile-menu-btn{
		span{
			&:before,
			&:after{
				background:#000;
			}
		}
	}
}
