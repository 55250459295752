/* Markup: Skweb (skt.pw) */
@import "utils/vars";
@import "utils/mixins";
@import "modules/modals";
@import "utils/global-styles";
@import "modules/header";

body.loaded-page{
	.top-screen-section{
		.sect-bg{
			img{
				opacity:1;
				transform:scale(1);
			}
		}
	}
}
.top-screen-section{
	width:100%;
	position:relative;
	height:100vh;
	min-height:700px;
	overflow:hidden;
	background:#000;
	@media #{$media-xs}{
		height: calc(var(--vh, 1vh) * 100);
		min-height:400px;
	}
	.section-holder{
		width:100%;
		height:100%;

	}
	.sect-bg{
		width:100%;
		height:100%;
		background:#666;
		img{
			width:100%;
			height:100%;
			object-fit: cover;
			opacity:0;
			transition:opacity 1s ease, transform 3s ease;
			transform:scale(1.2);
		}
	}
	.sect-content-box{
		@include full-height-abs;
		display:flex;
		flex-direction:column;
		flex-wrap:nowrap;
		justify-content:flex-end;
		align-items:stretch;
		padding-bottom:10vh;
		padding-top:100px;
		z-index:5;
		color:#fff;
		overflow:hidden;
	}
	.content-row{
		display:flex;
		flex-direction:row;
		flex-wrap:nowrap;
		justify-content:space-between;
		align-items:center;
		@media #{$media-xs}{
			display:block;
		}
	}
	.buttons-row{
		display:flex;
		flex-direction:row;
		flex-wrap:nowrap;
		justify-content:flex-start;
		align-items:center;
		@media #{$media-xs}{
			position:absolute;
			bottom:0;
			display:block;
		}
		._g-red-arrow-btn{
			white-space: nowrap;
			@media (min-width:768px) {
				opacity:0;
			}

		}
		.mouse-link{
			margin-left:15%;
			min-width:27px;
			opacity:0;
			@media #{$media-sm}{
				display:none;
			}
		}
	}
	.left-col{
		width:50%;
		position:relative;
		padding-top:7vh;
		padding-right:20%;
		@media #{$media-xs}{
			padding: 0;
			width:100%;
		}
		.star-image{
			position:absolute;
			top:0;
			right:0;
			width:25%;
			max-width:170px;
			@media #{$media-sm}{
				width:30%;
				top:auto;
				bottom:90%;
				right:-10%;
			}
			@media #{$media-xs}{
				top:10%;
			}
			img {
				@media (min-width:768px) {
					transform:scale(0.5);
					opacity:0;
				}
			}
		}
		h1 {
			font-size:5vw;
			line-height:4.5vw;
			font-weight:bold;
			margin:0 0 5vh;
			@media #{$media-xxlg}{
				font-size:90px;
				line-height:74px;
			}
			@media #{$media-sm}{
				font-size:7vw;
				line-height:7vw;
			}
			@media #{$media-xs}{
				font-size:10vw;
				line-height:10vw;
			}
		}
		.subtitle {
			display:block;
			font-size:1.8vw;
			font-weight:300;
			margin-bottom:5vh;
			@media (min-width:768px) {
				transform:translateY(20px);
				opacity:0;
			}
			@media #{$media-xxlg}{
				font-size:29px;
			}
			@media #{$media-xs}{
				font-size:5vw;
				line-height:6vw;
				width:60%;
			}
		}
	}

	.right-col {
		position:relative;
		width:40%;
		@media #{$media-xs}{
			width:100%;
		}
		@media (min-width:768px) {
			transform:translateX(20px);
			opacity:0;
		}

		._g-red-arrow-btn {
			@media #{$media-xs}{
				padding:0 10%;
				font-size:18px;
				line-height:18px;
				width:100%;
				height:60px;
				margin-bottom:10px;
				span {
					flex:1;
				}
				i {
					margin-left:15px;
				}
			}
		}

		._g-white-button {
			@media #{$media-xs}{
				width:100%;
				height:58px;
				text-align:center;
				font-size:18px;
				span {
					flex:1;
				}
				i {
					display:none;
				}
			}
		}

		.smalltext {
			font-size:12px;
			display:block;
			margin-bottom:3vh;
		}

		p {
			margin:0 0 3vh;
			font-size:27px;
			line-height:29px;
		}

		hr {
			width:50vw;
			border-color:rgba(255, 255, 255, 0.1);
			margin-bottom:3vh;
		}

		.pricefrom {
			display:block;
			font-weight:500;
			font-size:35px;
			margin-bottom:2vh;
		}
	}
}
._g-red-arrow-btn {
	background:$redColor;
	display:flex;
	flex-direction:row;
	flex-wrap:nowrap;
	justify-content:space-between;
	align-items:center;
	height:75px;
	font-size:21px;
	color:#ffffff;
	border-radius:10px;
	padding: 0 40px;
	font-weight:500;
	transition:all 0.3s ease;
	@media #{$media-sm}{
		padding: 0 20px;
		height:65px;
	}
	&:hover{
		background:#fff;
		border-color:rgba(255, 255, 255, 0.3);
		color:$redColor;
		i{
			background:$redColor;
			&:before {
				border-color:$redColor;
			}
		}
	}
	i {
		margin-left:22px;
		position:relative;
		display:block;
		height:2px;
		width:25px;
		background:#fff;
		transition:all 0.3s ease;
		&:before {
			@include after-box;
			display:inline-block;
			vertical-align:middle;
			border:solid #fff;
			border-width:0 2px 2px 0;
			padding:7px;
			right:2px;
			margin-top:-8px;
			transform:rotate(-45deg);
			position:absolute;
			top:50%;
			transition:all 0.3s ease;
		}
	}
}
.mouse-link{
	position:relative;
	padding-bottom:20px;
	img{
		width:27px;
		filter:invert(1) brightness(2);
	}
	i{
		position:absolute;
		left:50%;
		margin-left:-6px;
		bottom:0;
		display:block;
		animation:scrollArrow 2s ease infinite;
		&:after {
			@include after-box;
			display:inline-block;
			border:solid #fff;
			border-width:0 2px 2px 0;
			padding:5px;
			transform:rotate(45deg);

		}
	}

}
@keyframes scrollArrow {
	0% {
		transform:translateY(0);
	}
	50% {
		transform:translateY(10px);
	}
	100% {
		transform: translateY(0);
	}
}
._g-white-button {
	border:1px solid rgba(255, 255, 255, 0.62);
	position:relative;
	display:flex;
	flex-direction:row;
	flex-wrap:nowrap;
	justify-content:space-between;
	align-items:center;
	height:54px;
	border-radius:10px;
	padding:0 25px;
	color:#fff;
	transition:all 0.3s ease;
	font-weight:500;
	&:hover{

		border-color:#fff;

	}
	i {
		margin-left:18px;
		position:relative;
		display:block;
		height:2px;
		width:20px;
		background:rgba(255, 255, 255, 0.62);
		transition:all 0.3s ease;
		&:before {
			@include after-box;
			display:inline-block;
			vertical-align:middle;
			border:solid rgba(255, 255, 255, 0.62);
			border-width:0 2px 2px 0;
			padding:5px;
			right:0;
			margin-top:-6px;
			transform:rotate(-45deg);
			position:absolute;
			top:50%;
			transition:all 0.3s ease;
		}
	}
}
.cando-section {
	background:#FCFCFC;
	position:relative;
	padding: 120px 0 90px;
	@media #{$media-xs}{
		background:#ECECED;
		padding: 50px 0 25px;
	}
	.short-about-text {
		text-align:center;
		width:80%;
		margin:0 auto 80px;
		p {
			font-size:40px;
			line-height:44px;
			font-weight:normal;
			@media #{$media-xs}{
				font-size:22px;
				line-height:24px;
			}
		}
	}
	.cando-row{
		display:flex;
		flex-direction:row;
		flex-wrap:nowrap;
		justify-content:space-between;
		align-items:flex-start;
		@media #{$media-sm}{
			display:block;
		}
		@media #{$media-xs}{
			display:block;
		}
		.left-col{
			width:40%;
			padding-right:20px;
			@media #{$media-sm}{
				width:100%;
				margin-bottom:30px;
			}
			@media #{$media-xs}{
				width:100%;
				padding-right:0;
			}
			h2{
				font-size:2.5vw;
				line-height:3vw;
				color:#000;
				margin:0 0 55px;
				@media #{$media-sm}{
					font-size:5vw;
					line-height:6vw;
					margin-bottom:40px;
				}
				@media #{$media-xs}{
					margin:0 0 30px;
					font-size:7vw;
					line-height:7vw;
				}
				b{
					background:$redColor;
					color:#fff;
					padding: 0 1px;
				}
				@media #{$media-xxlg}{
					font-size:45px;
					line-height:44px;
				}
			}
			.subtext {
				color:#8c8c8c;
				font-size:15px;
			}
		}
		.right-col {
			flex:1;
			min-width:600px;
			padding-left:5%;
			@media #{$media-sm}{
				padding-left:0;
			}
			@media #{$media-xs}{
				min-width:0;
				padding:0;
				margin:0 -3vw;
			}
		}
	}
	.cando-list{
		display:flex;
		flex-direction:row;
		flex-wrap:wrap;
		justify-content:space-between;
		align-items:stretch;
		li{
			width:48%;
			margin-bottom:4%;
			display:flex;
			flex-direction:row;
			flex-wrap:nowrap;
			justify-content:space-between;
			align-items:stretch;
			height:165px;
			background:#fff;
			box-shadow:0 0 30px rgba(0, 0, 0, 0.02);
			padding: 25px 5%;
			@media #{$media-xs}{
				flex-direction:column;
				height:auto;
				padding: 20px 3%;
				min-height:130px;
			}
			.name{
				display:flex;
				flex-direction:row;
				flex-wrap:nowrap;
				justify-content:flex-start;
				align-items:flex-end;
				font-weight:500;
				font-size:19px;
				line-height:21px;
				@media #{$media-xs}{
					order:2;
					font-size:13px;
					line-height:15px;
				}
			}
			i{
				width:70px;
				min-width:70px;
				display:flex;
				flex-direction:row;
				flex-wrap:nowrap;
				justify-content:flex-end;
				align-items:flex-start;
				@media #{$media-xs}{
					width:100%;
					margin-bottom:10px;
					justify-content:flex-start;
				}
			}
			img{
				max-width:44px;
				max-height:45px;
				filter: invert(13%) sepia(80%) saturate(7432%) hue-rotate(351deg) brightness(85%) contrast(111%);
				@media #{$media-xs}{
					max-width:32px;
					max-height:35px;
				}
			}
		}
	}
}
.locdo-section{
	position:relative;
	color:#fff;
	padding: 70px 0 50px;
	@media #{$media-xs}{
		padding: 40px 0 25px;
	}
	.sectbg{
		@include full-height-abs;
		img{
			width:100%;
			height:100%;
			object-fit: cover;
		}
	}
	.sect-content{
		position:relative;
		z-index:5;
	}
	.subtext{
		display:block;
		font-size:15px;
		color:rgba(251, 251, 251, 0.69);
		margin-bottom:50px;
		@media #{$media-xs}{
			margin-bottom:20px;
			font-size:12px;
		}
	}
	.textrow{
		position:relative;
		display:flex;
		flex-direction:row;
		flex-wrap:nowrap;
		justify-content:flex-start;
		align-items:flex-end;
		.text{
			flex:1;
			max-width:600px;
			@media #{$media-xs}{

			}
			p{
				font-size:3vw;
				line-height:3vw;
				font-weight:500;
				@media #{$media-xxlg}{
					font-size:55px;
					line-height:44px;
				}
				@media #{$media-xs}{
					font-size:26px;
					line-height:22px;
				}
			}
		}
		.icon{

			width:91px;
			@media #{$media-xs}{
				width:40px;
			}
			img{
				width:100%;
			}
		}
	}
	._g-red-arrow-btn{
		width:100%;
		text-align:center;
		font-size:27px;
		display:flex;
		flex-direction:row;
		flex-wrap:nowrap;
		justify-content:center;
		align-items:center;
		@media #{$media-xs}{
			padding:0 10%;
			font-size:18px;
			line-height:18px;
			width:100%;
			height:60px;
			margin-bottom:10px;
			span {
				flex:1;
			}
		}
		&:hover{
			background:#fff;
			color:$redColor;
			i{
				background:$redColor;

				&:before {
					border-color:$redColor;
				}
			}
		}
		i{
			width:50px;
			@media #{$media-xs}{
				margin-left:15px;
				width:30px;
			}
		}
	}
	.free-row{
		display:flex;
		flex-direction:row;
		flex-wrap:nowrap;
		justify-content:space-between;
		align-items:stretch;
		margin-bottom:60px;
		@media #{$media-xs}{
			display:block;
			margin-bottom:40px;
		}
		.left-col{
			width:43%;
			@media #{$media-xs}{
				width:100%;
				margin-bottom:20px;
			}
		}
		.right-col {
			width:50%;
			border-left:1px solid #ABABAA;
			padding-left:5%;
			padding-right:5%;
			display:flex;
			flex-direction:column;
			flex-wrap:nowrap;
			justify-content:center;
			align-items:stretch;
			@media #{$media-xs}{
				width:100%;
				padding:0;
				border:none;
			}

			p {
				font-size:22px;
				line-height:23px;
				font-weight:500;
				@media #{$media-xs}{
					font-size:16px;
					line-height:18px;
				}
			}
		}
		.col-item{
			border-bottom:1px solid #ABABAA;
			padding: 10% 0px;
			display:flex;
			flex-direction:row;
			flex-wrap:nowrap;
			justify-content:flex-start;
			align-items:center;
			@media #{$media-xs}{
				padding: 6% 0px;
			}
			&:first-child {
				padding-top:0;
				@media #{$media-xs}{
					border-top:1px solid #ABABAA;
					padding-top:6%;
				}
			}
			&:last-child{
				border:none;
				padding-bottom:0;
			}
			.icon{
				width:30%;
				min-width:50px;
				display:flex;
				flex-direction:row;
				flex-wrap:nowrap;
				justify-content:flex-end;
				align-items:center;
				@media #{$media-xs}{
					order:-1;
					min-width:60px;
					justify-content:center;
					img{
						max-width:35px;
					}
				}
			}
		}
	}
}
.fed-sect{
	padding: 65px 0 40px;
	@media #{$media-xs}{
		padding: 40px 0 30px;
	}
	.small-toptext{
		display:block;
		font-size:15px;
		color:#8c8c8c;
		margin-bottom:70px;
	}
	.centered-text {
		width:80%;
		margin:0 auto 50px;
		text-align:center;
		max-width:980px;
		@media #{$media-xs}{
			width:100%;
			margin-bottom:30px;
		}
		p{
			font-size:40px;
			line-height:44px;
			@media #{$media-xs}{
				font-size:26px;
				line-height:30px;
			}
			a{
				color:$redColor;
				transition:all 0.3s ease;
				position:relative;
				padding-right:40px;
				&:hover{
					span{
						border-color:transparent;
					}
					i{
						transform:scale(1.2);
					}
				}
				span{
					border-bottom:1px solid $redColor;
				}
				i{
					position:absolute;
					right:0;
					bottom:5px;
					width:23px;
					display:block;
					vertical-align:middle;
					margin-left:20px;
					filter: invert(13%) sepia(80%) saturate(7432%) hue-rotate(351deg) brightness(85%) contrast(111%);
					transition:all 0.3s ease;
					@media #{$media-xs}{
						display:none;
					}
					img{
						width:100%;
					}
				}
			}
		}
	}
	.gerb-box{
		display:flex;
		flex-direction:column;
		flex-wrap:nowrap;
		justify-content:flex-start;
		align-items:center;
		text-align:center;
		.gerb-image{
			border-radius:50%;
			width:315px;
			height:315px;
			background:#F7F7F7;
			display:flex;
			flex-direction:row;
			flex-wrap:nowrap;
			justify-content:center;
			align-items:center;
			margin-bottom:25px;
			@media #{$media-xs}{
				width:245px;
				height:245px;
				padding: 0 20%;
			}
		}
		.sert-image{
			display:flex;
			flex-direction:row;
			flex-wrap:nowrap;
			justify-content:center;
			align-items:center;
			margin-bottom:20px;
			border:2px dashed #E10010;
			@media #{$media-xs}{

			}
			img{
				max-width:250px;
				@media #{$media-xs}{
					max-width:200px;
				}
			}
		}
		.gerb-text{
			display:block;
			text-align:center;
			font-size:15px;
			line-height:14px;
			color:#8c8c8c;
		}
	}
}
.why-choose-carousel{
	padding: 20px 0 150px;
	@media #{$media-sm}{
		padding-bottom:100px;
	}
	@media #{$media-xs}{
		padding-bottom:30px;
	}
	.swiper-wrapper{
		margin-bottom:50px;
		@media #{$media-xs}{
			margin-bottom:40px;
		}
	}
	.swiper-scrollbar{
		width:20%;
		background:#E4E4E4;
		@media #{$media-xs}{
			width:60%;
		}
		.swiper-scrollbar-drag{
			background:#aaa;
		}
	}
	.swiper-slide{
		width:23%;
		display:flex;
		flex-direction:column;
		flex-wrap:nowrap;
		justify-content:space-between;
		align-items:stretch;
		border-left:1px solid #9D9D9D;
		min-height:260px;
		padding: 10px 2%;
		@media #{$media-sm}{
			width:250px;
			min-height:210px;
		}
		@media #{$media-xs}{
			width:220px;
			min-height:200px;
			padding: 10px 10% 10px 20px;
			border-color:#DADADA;
			.image{
				margin-bottom:25px;
			}
		}
		.item-text{
			font-size:31px;
			line-height:36px;
			@media #{$media-sm}{
				font-size:28px;
				line-height:30px;
			}
			@media #{$media-md}{
				font-size:26px;
				line-height:30px;
			}
			@media #{$media-xs}{
				font-size:22px;
				line-height:25px;
			}
		}
		&:last-child{
			margin-right:5vw;
		}
		&:nth-child(even){
			min-height:160px;
			.item-text{
				font-size:19px;
				line-height:22px;
				@media #{$media-md}{
					font-size:17px;
					line-height:20px;
				}
				@media #{$media-xs}{
					font-size:14px;
					line-height:16px;
					min-height:100px;
				}
			}
		}

	}
	.carousel-heading-row{
		display:flex;
		flex-direction:row;
		flex-wrap:nowrap;
		justify-content:space-between;
		align-items:center;
		margin-bottom:70px;
		@media #{$media-xs}{
			margin:0 0 40px;
			display:block;
		}
		.carousel-buttons{
			display:flex;
			flex-direction:row;
			flex-wrap:nowrap;
			justify-content:flex-start;
			align-items:center;
			@media #{$media-xs}{
				display:none;
			}
			button{
				border-radius:50%;
				width:60px;
				height:60px;
				cursor:pointer;
				display:flex;
				flex-direction:row;
				flex-wrap:nowrap;
				justify-content:center;
				align-items:center;
				border:1px solid #DFDFDF;
				margin-left:10px;
				position:relative;
				transition:all 0.3s ease;
				padding-right:5px;
				&:hover{
					border-color:$redColor;
					background:$redColor;

					&:before {
						border-color:#fff;
					}
				}
				&:before {
					@include after-box;
					display:inline-block;
					border:solid #000;
					border-width:0 1px 1px 0;
					padding:5px;
					transform:rotate(-45deg);
				}
			}
			.btn-prev{
				padding-left:5px;
				padding-right:0;
				&:before {
					transform:rotate(135deg);
				}
			}
		}
	}
	h2{
		font-size:70px;
		line-height:60px;
		font-weight:500;
		@media #{$media-xs}{
			font-size:8vw;
			line-height:8vw;
		}
	}
	.swiper-out-right{
		margin-right:-5vw;
	}
}
.about-service-sect{
	padding: 20px 0 80px;
	@media #{$media-xs}{
		padding: 0 0 30px;
	}
	.sect-heading-row {
		display:flex;
		flex-direction:row;
		flex-wrap:nowrap;
		justify-content:space-between;
		align-items:center;
		border-bottom:2px solid #000000;
		margin-bottom:45px;
		padding-bottom:20px;
		@media #{$media-xs}{
			display:block;
			padding-bottom:10px;
			margin-bottom:30px;
		}

		h2 {
			font-size:70px;
			font-weight:500;
			margin:0;
			@media #{$media-xs}{
				font-size:12vw;
			}
		}

		.subtext {
			display:block;
			font-size:15px;
			line-height:16px;
			color:#818181;
			@media #{$media-xs}{
				display:none;
			}
		}

		.left-col {
			width:50%;
			@media #{$media-xs}{
				width:100%;
			}
		}

		.right-col {
			width:45%;
			@media #{$media-xs}{
				width:100%;
			}
		}
	}
	.sect-main-row{
		display:flex;
		flex-direction:row;
		flex-wrap:nowrap;
		justify-content:space-between;
		align-items:flex-start;
		position:relative;
		margin-bottom:120px;
		@media #{$media-xs}{
			margin:0 0 30px;
			flex-direction:column;
		}
		.picture-round{
			position:absolute;
			left:50%;
			top:150px;
			width:40%;
			transform:translateX(-50%);
			border-radius:50%;
			overflow:hidden;
			max-width:560px;
			@media #{$media-sm}{
				width:60%;
			}
			@media #{$media-xs}{
				position:relative;
				top:auto;
				left:auto;
				width:100%;
				transform:none;
				margin-bottom:30px;
			}
			&:after {
				@include after-box;
				padding-bottom:100%;
			}
			picture{
				@include full-height-abs;
				width:150%;
				margin-left:-15%;

				@media #{$media-xs}{
					width:100%;
					margin:0;
				}
				@media #{$media-xs}{
					img{
						width:100%;
						height:100%;
						object-fit: cover;
					}
				}
			}
		}
		.left-col {
			width:50%;
			position:relative;
			z-index:3;
			padding:0px 3% 0 10%;
			@media #{$media-sm}{
				padding-left:0;
			}
			@media #{$media-md}{
				padding-left:5%;
			}
			@media #{$media-xs}{
				order:3;
				padding:20px 0 0;
				width:100%;
			}

		}
		.right-col{
			width:45%;
			padding-right:5%;
			@media #{$media-sm}{
				padding-right:0;
				width:50%;
			}
			@media #{$media-xs}{
				width:100%;
				padding: 0;
			}
		}
	}
	p{
		font-size:35px;
		line-height:37px;
		font-weight:500;
		@media #{$media-sm}{
			font-size:28px;
			line-height:32px;
		}
		@media #{$media-md}{
			font-size:33px;
			line-height:35px;
		}
		@media #{$media-xs}{
			font-size:23px;
			line-height:24px;
		}
	}
	.col-top{
		margin-bottom:28vw;
		position:relative;
		z-index:3;
		@media #{$media-sm}{
			margin-bottom:35vw;
		}
		@media #{$media-xxlg}{
			margin-bottom:500px;
		}
		@media #{$media-xs}{
			margin-bottom:-10vw;
		}
	}

	.door-content-box{
		position:relative;
		padding: 200px 40px 80px;
		width:379px;
		color:#fff;
		@media #{$media-sm}{
			width:300px;
			padding: 180px 30px 60px;
		}
		@media #{$media-xs}{
			width:100%;
			padding: 180px 30px 30px;
		}
		&:before {
			@include after-box;
			position:absolute;
			left:0;
			width:100%;
			top:0;
			background:url(../images/about-service-door-locker.png) no-repeat;
			height:100%;
			@media #{$media-sm}{
				background-size:100% auto;
			}
			@media #{$media-xs}{
				background-size:100% auto;
			}
		}
		.icon{
			margin-bottom:45px;
			@media #{$media-xs}{
				margin-bottom:30px;
			}
		}
		p{
			font-size:18px;
			line-height:23px;
			@media #{$media-sm}{
				font-size:16px;
				line-height:19px;
			}
			@media #{$media-xs}{
				font-size:14px;
				line-height:18px;
				font-weight:normal;
			}
		}
		.locker-holder{
			position:relative;
			z-index:3;
		}
	}
	.about-accordion{
		position:relative;
		z-index:3;
		.card {
			border-bottom:1px solid #000;
			margin-bottom:15px;
			padding-bottom:15px;
			@media #{$media-xs}{
				margin-bottom:10px;
				padding-bottom:10px;
			}
		}
		.card-body{
			padding-top:10px;
		}
		.acc-link{
			display:flex;
			flex-direction:row;
			flex-wrap:nowrap;
			justify-content:space-between;
			align-items:center;
			transition:all 0.3s ease;
			font-size:21px;
			font-weight:500;
			color:$redColor;
			padding-right:15px;
			width:100%;
			@media #{$media-sm}{
				font-size:18px;
			}
			@media #{$media-xs}{
				font-size:15px;
			}
			&:hover {
				color:#000000;
				i{
					filter:none
				}
			}
			i{
				margin-left:10px;
				transition:all 0.3s ease;
				width:22px;
				transform:rotate(90deg);
				filter: invert(13%) sepia(80%) saturate(7432%) hue-rotate(351deg) brightness(85%) contrast(111%);
				@media #{$media-xs}{
					width:17px;
				}
			}
		}
		p{
			font-size:18px;
			line-height:23px;
			@media #{$media-xs}{
				font-size:16px;
				line-height:19px;
				font-weight:normal;
			}
		}
	}
	.results-heading-row{
		display:flex;
		flex-direction:row;
		flex-wrap:nowrap;
		justify-content:space-between;
		align-items:center;
		padding: 0 5% 50px 10%;
		@media #{$media-sm}{
			padding: 0 0 50px;
		}
		@media #{$media-xs}{
			padding: 0 0 30px;
			.stamp-col{
				width:30%;
			}
		}
		.title-col{
			flex:1;
			max-width:800px;
			padding-right:5%;
			@media #{$media-xs}{
				padding-right:0;
			}
		}
		.results-title {
			display:block;
			font-size:45px;
			line-height:44px;
			font-weight:500;
			@media #{$media-sm}{
				font-size:38px;
				line-height:40px;
			}
			@media #{$media-xs}{
				font-size:22px;
				line-height:24px;
			}
		}

	}
	.plefted-box{
		padding-left:10%;
		padding-bottom:50px;
		@media #{$media-sm}{
			padding-left:0;
			padding-bottom:20px;
		}
		@media #{$media-xs}{
			padding: 0 0 20px;
		}
		.galka-list{
			padding: 0 6% 0 5%;
		}
	}
	.classification-box {
		border:2px solid $redColor;
		border-radius:10px;
		margin-bottom:50px;
		padding: 40px 50px;
		@media #{$media-xs}{
			padding: 30px 6%;
			margin-bottom:30px;
		}
		.class-row{
			display:flex;
			flex-direction:row;
			flex-wrap:nowrap;
			justify-content:space-between;
			align-items:flex-start;
			@media #{$media-xs}{
				display:block;
			}
			h3 {
				font-size:28px;
				font-weight:500;
				line-height:25px;
				@media #{$media-xs}{
					margin-bottom:10px;
				}
			}
			.main-col {
				flex:1;
				margin-left:3%;
				@media #{$media-xs}{
					margin:0;
				}
			}
			p {
				font-size:15px;
				color:#818181;
				line-height:17px;
				font-weight:normal;
			}
		}
	}
}
.galka-list{
	margin-bottom:50px;
	@media #{$media-xs}{
		margin-bottom:20px;
	}
	li{
		position:relative;
		padding: 20px 0 20px 60px;
		border-bottom:1px solid #ECECEC;
		font-size:21px;
		font-weight:500;
		color:#000;
		@media #{$media-xs}{
			padding: 10px 0 10px 50px;
			font-size:18px;
			line-height:21px;
		}
		&:before {
			@include after-box;
			position:absolute;
			top:50%;
			transform:translateY(-50%);
			left:0;
			height:27px;
			width:27px;
			background:url(../images/SVG/galka.svg) no-repeat;
			background-size:27px auto;
		}
		&:last-child{
			margin-bottom:0;
			border:none;
			padding-bottom:0;
		}
		em{
			font-style:normal;
			font-weight:normal;
			font-size:14px;
			color:$redColor;
			display:block;
			@media #{$media-xs}{
				font-size:12px;
				line-height:13px;
				margin-top:5px;
			}
		}
	}
}
.work-feat-section{
	position:relative;
	width:100%;
	padding: 135px 0 80px;
	overflow:hidden;
	@media #{$media-xs}{
		padding: 80px 0 50px;
	}
	.sectbg{
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100vh;
		@media #{$media-xs}{
			height:100%;
		}
		picture{
			width:100%;
			display:block;
			transform:translateY(-50%);
			height:100%;
			@media #{$media-xs}{
				transform:none;
			}
		}
		img{
			width:100%;
			height:100%;
			object-fit: cover;

		}
	}
	.sect-content{
		position:relative;
		z-index:4;
	}
	.center-text{
		margin:0 auto 60px;
		max-width:860px;
		width:70%;
		color:#fff;
		@media #{$media-xs}{
			width:100%;
			margin:0 0 30px;
		}
		p{
			text-align:center;
			font-size:58px;
			line-height:53px;
			font-weight:500;
			margin:0 0 30px;
			@media #{$media-xs}{
				font-size:28px;
				line-height:32px;
				margin-bottom:40px;
			}
		}
	}
	.indi-box{
		position:relative;
		padding-left:50%;
		margin-bottom:100px;
		@media #{$media-xs}{
			padding-left:0;
			margin-bottom:40px;
			text-align:center;
		}
		&:before {
			@include after-box;
			height:1px;
			margin-bottom:15px;
			background:rgba(255,255,255,0.4);
			width:60vw;
			@media #{$media-xs}{
				width:100%;
			}
		}
		.indi-text{
			color:rgba(255, 255, 255, 0.79);
			display:block;
			@media #{$media-xs}{
				br{
					display:none !important;
				}
			}
		}
	}
	._g-red-arrow-btn{
		width:100%;
		justify-content:center;
		@media #{$media-xs}{
			padding: 0 10%;
			font-size: 18px;
			line-height: 18px;
			width: 100%;
			height: 60px;
			margin-bottom: 10px;
		}
	}
}
.prices-service-section {
	background:#ffffff;
	position:relative;
	z-index:4;
	padding: 115px 0 200px;
	@media #{$media-xs}{
		padding: 60px 0 30px;
	}
	.prices-sect-row{
		display:flex;
		flex-direction:row;
		flex-wrap:nowrap;
		justify-content:space-between;
		align-items:stretch;
		margin-bottom:60px;
		@media #{$media-sm}{
			margin-bottom:30px;
		}
		@media #{$media-xs}{
			display:block;
			margin-bottom:10px;
		}
		.title-col{
			width:30%;
			@media #{$media-sm}{
				width:25%;
			}
			@media #{$media-xs}{
				width:100%;
				margin-bottom:20px;
			}
		}
		.main-col{
			flex:1;
			padding-top:10px;
			@media #{$media-xs}{
				margin-right:-6vw;
			}
		}
	}
	h2 {
		font-size:4vw;
		line-height:3.5vw;
		font-weight:500;
		@media #{$media-xxlg}{
			font-size:70px;
			line-height:52px;
		}
		@media #{$media-xs}{
			font-size:11vw;
			line-height:11vw;
		}
	}
	.prices-text-info {
		font-size:13px;
		line-height:15px;
		color:#606060;
		display:block;
		max-width:220px;
		@media #{$media-sm}{
			max-width:none;
			padding-left:25%;
		}
		@media #{$media-xs}{
			max-width:none;
		}
	}
	.table-info {
		width:100%;
		display:block;
		font-size:12px;
		color:#9f9f9f;
		line-height:12px;
	}
	.table-wrapper{
		@media #{$media-xs}{
			width:100%;
			overflow: scroll;
			margin-bottom:10px;
		}
	}
}
.prices-table{
	width:100%;
	margin-bottom:30px;
	@media #{$media-xs}{
		max-width: 100%;
		margin-bottom:10px;
	}
	td {
		text-align:center;
		padding: 12px 10px 15px;
		@media #{$media-sm}{
			padding: 10px 8px 12px;
		}
		@media #{$media-xs}{
			padding: 5px 6px;
		}
		&:first-child {
			padding-left:0;
			text-align:left;
		}
	}
	thead{
		td {
			color:#636363;
			font-size:13px;
			line-height:15px;
			padding:0 10px 15px;
			@media #{$media-sm}{
				font-size:12px;
				line-height:14px;
				padding: 0 8px 0px;
				br{
					display:none !important;
				}
			}
			@media #{$media-xs}{
				padding:0 6px 0;
				font-size:11px;
				line-height:13px;
				vertical-align:middle;
			}

			&:first-child {
				padding-left:0;
			}
		}
	}
	tbody{
		td{
			border-bottom:1px solid #EEEEEE;
			padding: 12px 10px 15px;
			font-weight:500;
			font-size:20px;
			white-space: nowrap;
			@media #{$media-sm}{
				font-size:16px;
				padding: 10px 8px 12px;
				white-space: nowrap;
			}
			@media #{$media-xs}{
				padding: 8px 6px;
				font-size:14px;
				white-space: nowrap;
			}
			&:first-child {
				padding-left:0;
				@media #{$media-md}{
					white-space: normal;
				}
			}
			&:last-child{

				@media #{$media-xs}{
					padding-right:6vw;
				}
			}
		}
		tr{
			&:last-child{
			    td{
					border:none;
				}
			}
		}
	}
}
.dasha-sister-section{
	position:relative;
	z-index:6;
	width:100%;
	margin-bottom:50px;
	@media #{$media-xs}{
		margin-bottom:20px;
	}
	.sect-row{
		display:flex;
		flex-direction:row;
		flex-wrap:nowrap;
		justify-content:space-between;
		align-items:stretch;
		@media #{$media-xs}{
			display:block;
		}
	}
	.col{
		width:50%;
		@media #{$media-xs}{
			width:100%;
		}
		picture{
			display:block;
			transform:translateY(-10vw);
			@media #{$media-xxlg}{
				transform:translateY(-100px);
			}
			@media #{$media-xs}{
				transform:none;
				margin-bottom:-1px;
			}
			img{
				width:100%;
			}
		}
	}
	.spec-about-box{
		height:100%;
		display:flex;
		flex-direction:column;
		flex-wrap:nowrap;
		justify-content:space-between;
		align-items:stretch;
		background:#2A2A2A;
		color:#fff;
		padding: 5% 10% 0;
		@media #{$media-xs}{
			padding: 30px 6vw 20px;
			margin-top:-2px;
		}
		.role {
			font-size:40px;
			line-height:41px;
			font-weight:500;
			@media #{$media-sm}{
				font-size:22px;
				line-height:23px;
			}
			@media #{$media-xs}{
				font-size:20px;
				line-height:22px;
				margin-bottom:30px;
			}
		}
		.center-box {
			flex:1;
			display:flex;
			flex-direction:row;
			flex-wrap:nowrap;
			justify-content:center;
			align-items:center;
			@media #{$media-xs}{
				margin-bottom:30px;
			}
		}
		.bottom-row{
			display:flex;
			flex-direction:row;
			flex-wrap:nowrap;
			justify-content:space-between;
			align-items:flex-end;
			@media #{$media-xs}{
				display:block;
			}
			.exp-text {
				max-width:200px;
				font-size:16px;
				line-height:20px;
				margin-bottom:20px;
				@media #{$media-sm}{
					font-size:14px;
					line-height:18px;
					padding-right:10px;
				}
				@media #{$media-xs}{
					max-width:none;
					color:#eeeeee;
					margin-bottom:10px;
					display:block;
				}
			}
		}
		.more-box {
			margin-right:-5vw;
			width:60%;
			max-width:350px;
			@media #{$media-xs}{
				width:100%;
				margin:0;
				max-width:none;
				border-radius:20px;
			}
		}
		.name{
			display:block;
			font-size:66px;
			line-height:53px;
			text-align:center;
			font-weight:500;
			@media #{$media-sm}{
				font-size:7vw;
				line-height:7vw;
			}
			@media #{$media-xs}{
				font-size:11vw;
				line-height:11vw;
			}
		}
		.more{
			background:$redColor;
			width:100%;
			display:flex;
			flex-direction:row;
			flex-wrap:nowrap;
			justify-content:center;
			align-items:center;
			border-radius:10px 0 0 0;
			padding: 30px 5%;
			font-size:26px;
			font-weight:500;
			transition:all 0.3s ease;
			@media #{$media-sm}{
				font-size:22px;
				padding: 10px 5%;
			}
			@media #{$media-xs}{
				padding: 10px 5%;
				border-radius:10px;
				font-size:22px;
			}
			&:hover{
				background:#000;
				color:#fff;
			}
			i{
				width:35px;
				margin-left:10%;
				@media #{$media-sm}{
					display:none;
				}
				@media #{$media-xs}{
					display:none;
				}
				img{
					filter:invert(1);
				}
			}
		}
	}
}
.law-section {
	background:#ffffff;
	position:relative;
	width:100%;
	padding: 5vw 0 120px;
	@media #{$media-sm}{
		padding-bottom:80px;
	}
	@media #{$media-xs}{
		padding: 5vw 0 40px;
	}
	h2 {
		font-size:60px;
		line-height:60px;
		font-weight:500;
		margin:0 0 30px;
		@media #{$media-xs}{
			font-size:9vw;
			line-height:9vw;
			margin:0 0 20px;
		}
	}
	hr {
		border-color:#BBBBBB;
	}
	.subtext{
		display:block;
		margin-bottom:60px;
		font-size:12px;
		color:#606060;
		@media #{$media-sm}{
			margin-bottom:30px;
		}
		@media #{$media-xs}{
			margin-bottom:20px;
		}
	}
	.law-top-row{
		display:flex;
		flex-direction:row;
		flex-wrap:nowrap;
		justify-content:space-between;
		align-items:center;
		margin-bottom:70px;
		padding-bottom:45px;
		border-bottom:1px solid #999999;
		@media #{$media-xs}{
			display:block;
			margin-bottom:40px;
			border:none;
			padding-bottom:0;
		}
		.col-picture{
			display:flex;
			flex-direction:column;
			flex-wrap:nowrap;
			justify-content:center;
			align-items:center;
			flex:1;
			@media #{$media-xs}{
				margin-bottom:20px;
			}
		}
		.col-text {
			width:50%;
			min-width:500px;
			@media #{$media-xs}{
				min-width:0;
				width:100%;
			}
		}
	}
	.order-list{
		li{
			display:flex;
			flex-direction:row;
			flex-wrap:nowrap;
			justify-content:flex-start;
			align-items:flex-start;
			border-bottom:1px solid #B7B7B7;
			padding: 25px 10% 30px 0;
			@media #{$media-sm}{
				padding-right:0;
			}
			@media #{$media-xs}{
				padding: 15px 0 10px;
				&:first-child {
					padding-top:0;
				}
			}
			&:last-child {
				border:none;
				padding-bottom:0;
			}
			.icon{
				width:80px;
				display:flex;
				flex-direction:row;
				flex-wrap:nowrap;
				justify-content:center;
				align-items:flex-start;
				margin-top:5px;
				@media #{$media-xs}{
					width:40px;
					justify-content:flex-start;
				}
			}
			.desc{
				flex:1;
			}
			p{
				margin:0;
				font-size:20px;
				line-height:24px;
				@media #{$media-sm}{
					font-size:18px;
					line-height:20px;
				}
				@media #{$media-xs}{
					font-size:16px;
					line-height:19px;
				}
			}
			a{
				color:#000;
				&:hover{
					color:$redColor;
				}
			}
		}
	}
	.codex-row{
		display:flex;
		flex-direction:row;
		flex-wrap:nowrap;
		justify-content:space-between;
		align-items:stretch;
		@media #{$media-xs}{
			display:block;
		}
		p{
			font-size:20px;
			line-height:24px;
			margin:0 0 40px;
			@media #{$media-sm}{
				font-size:18px;
				line-height:20px;
				margin-bottom:30px;
			}
			@media #{$media-xs}{
				font-size:18px;
				line-height:21px;
				margin-bottom:20px;
			}
		}
		.left-col{
			width:50%;
			padding: 0 5%;
			@media #{$media-sm}{
				padding-left:0;
			}
			@media #{$media-xs}{
				width:100%;
				padding: 0 0 30px;
			}
		}
		.right-col{
			width:50%;
			padding-left:80px;
			border-left:1px solid #000;
			display:flex;
			flex-direction:column;
			flex-wrap:nowrap;
			justify-content:center;
			align-items:stretch;
			@media #{$media-sm}{
				padding-left:30px;
			}
			@media #{$media-xs}{
				border:none;
				padding: 0;
				width:100%;
			}
		}
	}
}
.bulleted-list{

	li {
		position:relative;
		padding-bottom:20px;
		padding-left:40px;
		font-weight:500;
		font-size:17px;
		line-height:23px;
		@media #{$media-xs}{
			padding-left:30px;
			font-size:16px;
			line-height:18px;
			&:last-child{
				padding-bottom:0;
			}
		}
		&:before {
			@include after-box;
			position:absolute;
			left:0;
			top:7px;
			width:20px;
			height:3px;
			background:$redColor;
			@media #{$media-xs}{
				height:2px;
				width:15px;
				top:10px;
			}
		}
	}
}
.addition-service-section {
	position:relative;
	z-index:3;
	background:#F1F1F2;
	padding: 70px 0 75px;
	@media #{$media-xs}{
		padding: 40px 0;
	}
	h2{
		font-weight:500;
		margin:0 0 55px;
		font-size:40px;
		line-height:42px;
		@media #{$media-xs}{
			font-size:9vw;
			line-height:9vw;
			margin-bottom:30px;
		}
	}
	.addition-row{
		display:flex;
		flex-direction:row;
		flex-wrap:nowrap;
		justify-content:space-between;
		align-items:stretch;
		@media #{$media-sm}{
			flex-wrap:wrap;
		}
		@media #{$media-xs}{
			flex-wrap:wrap;
		}
		.left-col{
			width:50%;
			padding-right:25px;
			@media #{$media-sm}{
				width:100%;
				padding-right:0;
				margin-bottom:20px;
			}
			@media #{$media-xs}{
				width:100%;
				padding-right:0;
				margin-bottom:20px;
			}
		}
	}
	.prepare-box {
		background:$redColor;
		padding:35px 5%;
		color:#fff;
		display:flex;
		flex-direction:row;
		flex-wrap:nowrap;
		justify-content:space-between;
		align-items:center;
		@media #{$media-xs}{
			padding:25px 5%;
			border-radius:10px;
			align-items:flex-start;
		}

		.prepare-left {
			flex:1;
			padding-right:5%;
			@media #{$media-xs}{
				padding: 0 0 0 5%;
			}
		}

		.prepare-right {
			width:20%;
			display:flex;
			flex-direction:row;
			flex-wrap:nowrap;
			justify-content:center;
			align-items:center;
			@media #{$media-xs}{
				padding-top:5%;
				order:-1;
			}
		}

		.title {
			display:block;
			font-size:33px;
			line-height:26px;
			font-weight:500;
			margin:0 0 15px;
			max-width:380px;
			@media #{$media-xs}{
				font-size:19px;
				line-height:22px;
			}
		}

		.date {
			display:block;
			font-size:14px;
			color:#ddcccd;
			margin-bottom:35px;
			@media #{$media-xs}{
				margin-bottom:20px;
			}
		}

		.btn-wrap {
			display:flex;
			flex-direction:row;
			flex-wrap:nowrap;
			justify-content:flex-start;
			align-items:center;
		}

		.more-link {
			display:flex;
			flex-direction:row;
			flex-wrap:nowrap;
			justify-content:flex-start;
			align-items:center;
			height:55px;
			border:1px solid rgba(255, 255, 255, 0.58);
			border-radius:8px;
			padding:0 25px;
			transition:all 0.3s ease;
			@media #{$media-xs}{
				margin-left:-30%;
				width:125%;
			}

			&:hover {
				background:#fff;
				color:#000;

				i {
					background:#000;

					&:before {
						border-color:#000;
					}
				}
			}

			i {
				position:relative;
				height:1px;
				width:18px;
				margin-left:18px;
				background:#fff;
				transition:all 0.3s ease;

				&:before {
					@include after-box;
					display:inline-block;
					position:absolute;
					margin-top:-5px;
					right:1px;
					border:solid #fff;
					border-width:0 1px 1px 0;
					padding:5px;
					transform:rotate(-45deg);
					transition:all 0.3s ease;
				}
			}
		}
	}
	.right-col{
		padding-left:25px;
		display:flex;
		flex-direction:row;
		flex-wrap:nowrap;
		justify-content:space-between;
		align-items:stretch;
		width:50%;
		@media #{$media-sm}{
			width:100%;
			padding: 0;
		}
		@media #{$media-xs}{
			width:100%;
			padding: 0;
		}
	}
	.add-item{
		background:#fff;
		width:48%;
		display:flex;
		flex-direction:column;
		flex-wrap:nowrap;
		justify-content:space-between;
		align-items:stretch;
		padding: 6% 5%;

		.name {
			display:block;
			font-size:21px;
			line-height:21px;
			font-weight:500;
			@media #{$media-md}{
				font-size:18px;
				line-height:18px;
			}
			@media #{$media-xs}{
				font-size:16px;
				line-height:19px;
			}
		}
		.pic{
			@media #{$media-xs}{
				margin-bottom:15px;
			}
		}
		.subname{
			display:block;
			font-size:13px;
			line-height:18px;
			color:#a9a9a9;
			margin-top:15px;
		}
	}

}
.question-section{
	position:relative;
	width:100%;
	z-index:4;
	padding: 120px 0 50px;
	@media #{$media-xs}{
		padding: 60px 0 30px;
	}
	.sectbg{
		@include full-height-abs;
		img{
			width:100%;
			height:100%;
			object-fit: cover;
		}
	}
	.sect-content{
		position:relative;
		z-index:3;
	}
	.questions-form{
		margin-bottom:70px;
		position:relative;
		.form-holder{
			position:relative;
			z-index:3;
			transition:all 0.3s ease;
		}
		@media #{$media-xs}{
			margin-bottom:50px;
		}

		.success-abs-box{
			@include full-height-abs;
			opacity:0;
			visibility:hidden;
			border-radius:20px;
			z-index:4;
			transition:all 0.3s ease;
			.centered{
				display:flex;
				flex-direction:column;
				flex-wrap:nowrap;
				justify-content:space-between;
				align-items:center;
				height:100%;
				padding: 20px 5% 40px;
			}
			.success-icon{
				position:relative;
				margin:0 auto 30px;

				@media #{$media-xs}{
					width:180px;
				}
				img{
					transition:all 0.3s ease;
					transform:scale(0.6);
					opacity:0;
					transition-delay: 0.2s;
					filter:grayscale(1) invert(1) brightness(2);
				}
			}
			.success-title {
				display:none;
			}
			.success-subtitle {
				display:block;
				font-weight:500;
				color:#fff;
				font-size:33px;
				@media #{$media-xs}{
					line-height:30px;
				}
			}
			.galka{
				width: 130px;
				height: 130px;
				position:absolute;
				top:50%;
				left:50%;
				margin-left:-65px;
				margin-top:-65px;
				&:before {
					@include after-box;
					height: 12px;
					width: 0px;
					left: 50%;
					background: #fff;
					position: absolute;
					top:50%;
					border-radius:6px;
					margin-top:-15px;
					margin-left: -50px;
					transform: rotate(45deg);
					transform-origin: 0 100%;
					transition: all 0.3s ease;
					transition-delay: 0.3s;
				}
				&:after {
					@include after-box;
					height: 12px;
					border-radius:6px;
					width: 0px;
					left: 50%;
					background: #fff;
					position: absolute;
					top:50%;
					margin-left:-7px;
					margin-top:28px;
					transform: rotate(-45deg);
					transform-origin:0 100%;
					transition: all 0.3s ease;
					transition-delay: .5s;
				}
			}
		}
		&.sended{
			.form-holder {
				opacity:0;
				visibility:hidden;
			}
			.success-abs-box{
				opacity:1;
				visibility:visible;
				.success-icon {
					img{
						transform:scale(1);
						opacity:1;
					}
				}
				.galka{
					&:before {
						width: 60px;
					}
					&:after {
						width: 100px;
					}
				}
			}
		}
		.form-title {
			display:block;
			font-size:50px;
			font-weight:500;
			margin-bottom:50px;
			color:#fff;
			text-align:center;
			@media #{$media-xs}{
				font-size:11vw;
				line-height:11vw;
				margin-bottom:40px;
			}
		}
		.form-row{
			display:flex;
			flex-direction:row;
			flex-wrap:nowrap;
			justify-content:space-between;
			align-items:stretch;
			max-width:1100px;
			margin:0 auto 40px;
			@media #{$media-xs}{
				display:block;
			}
			.col{
				width:48%;
				@media #{$media-xs}{
					width:100%;
				}
			}
		}
		.input-wrap{
			position:relative;
			label.error {
				position:absolute;
				left:-3px;
				color:#ffffff;
				font-weight:normal;
				padding:0 10px;
				border-radius:5px;
				font-size:12px;
				bottom:-5px;
				background:$redColor;
			}
		}
		.form-control{
			background:none;
			border-radius:0;
			border:none;
			border-bottom:1px solid rgba(255,255,255,0.5);
			font-size:23px;
			height:50px;
			padding-bottom:20px;
			box-shadow:none;
			outline:none;
			color:#fff;
			margin-bottom:15px;
			@media #{$media-xs}{
				margin-bottom:10px;
				padding-bottom:10px;
			}
			@include placeholder {
				color:rgba(255,255,255,0.6);
			}
		}
		.form-smalltext{
			display:block;
			font-size:12px;
			line-height:14px;
			color:#ababab;
			max-width:360px;
			a{
				color:#ababab;
				text-decoration:underline;
				&:hover{
					text-decoration:none;
					color:#fff;
				}
			}
		}
		.button-wrap{
			display:flex;
			flex-direction:row;
			flex-wrap:nowrap;
			justify-content:center;
			align-items:center;
			@media #{$media-xs}{
				width:100%;
				display:block;
				._g-red-arrow-btn{
					width:100%;
					height:60px;
				}
			}
		}

	}

}
footer{
	border-top:1px solid #999797;
	display:flex;
	flex-direction:row;
	flex-wrap:nowrap;
	justify-content:space-between;
	align-items:stretch;
	@media #{$media-xs}{
		flex-direction:column;
		border:none;
	}
	.left-col{
		width:340px;
		display:flex;
		flex-direction:column;
		flex-wrap:nowrap;
		justify-content:space-between;
		align-items:flex-start;
		padding: 15px 0;
		border-right:1px solid #999797;
		@media #{$media-md}{
			width:200px;
		}
		@media #{$media-sm}{
			width:130px;
			padding-top:30px;
		}
		@media #{$media-xs}{
			display:block;
			width:100%;
			border:none;
			border-top:1px solid #666;
			order:2;
			padding-bottom:0;
		}
		.copy{
			display:block;
			font-size:15px;
			color:#fff;
		}
		.logo{
			display:block;
			position:relative;
			margin-top:-5px;
			span {
				font-size:1.8vw;
				font-weight:300;
				display:block;
				@media #{$media-md}{
					font-size:2.3vw;
					line-height:2.5vw;
				}
				@media #{$media-sm}{
					font-size:2.3vw;
					line-height:2.5vw;
				}
				@media #{$media-xxlg}{
					font-size:30px;
				}
				@media #{$media-xs}{
					font-size:5vw;
				}

				b {
					font-weight:bold;
					@media #{$media-md}{
						display:block;
					}
					@media #{$media-sm}{
						display:block;
					}
				}
			}
			img{
				display:block;
				margin-bottom:-3px;
				@media #{$media-md}{
					margin-bottom:5px;
				}
				@media #{$media-sm}{
					margin-bottom:3px;
				}
			}
		}
	}
	.main-col{
		display:flex;
		flex-direction:row;
		flex-wrap:nowrap;
		justify-content:space-between;
		align-items:flex-start;
		padding: 25px 0 15px 3%;
		flex:1;
		@media #{$media-xs}{
			display:block;
			padding: 0 0 15px;
		}
		.adr-row{
			flex:1;
			.adr-flex {
				display:flex;
				flex-direction:row;
				flex-wrap:nowrap;
				justify-content:flex-start;
				align-items:flex-start;
				margin-bottom:10px;
				@media #{$media-xs}{
					display:block;
				}
			}

			.link-row{
				@media #{$media-xs}{
					display:flex;
					flex-direction:column;
					flex-wrap:nowrap;
					justify-content:flex-start;
					align-items:flex-start;
				}
				a {
					margin-right:10px;
					text-decoration:underline;
					opacity:0.7;
					transition:all 0.3s ease;
					@media #{$media-xs}{
						margin:0;
					}
					&:hover {
						opacity:1;
						text-decoration:none;
					}
					&:last-child{
						margin-right:0;
					}
				}
			}
		}
		.adr {
			display:block;
			color:#ffffff;
			font-size:15px;
			line-height:18px;
			padding-top:5px;
		}
		.phone-col{
			padding-right:40px;
			@media #{$media-md}{
				padding-right:20px;
			}
			@media #{$media-xs}{
				padding-right:0;
				padding-bottom:10px;
			}
			.link-box {
				margin-bottom:5px;
				&:last-child{
					margin-bottom:0;
				}
			}
			a{
				font-size:25px;
				font-weight:500;
				white-space: nowrap;
			}
		}
	}
	.design{
		color:rgba(255, 255, 255, 0.31);
		font-size:15px;
		@media #{$media-sm}{
			font-size:14px;
		}
		@media #{$media-xs}{
			display:block;
			position:absolute;
			right:6vw;
			bottom:0;
			text-align:right;
			font-size:14px;
		}
		span{
			margin-right:20px;
			@media #{$media-md}{
				display:block;
			}
			@media #{$media-sm}{
				display:block;
			}
			@media #{$media-xs}{
				margin:0 0 3px;
				display:block;
			}
		}
		a{
			font-weight:500;
		}
	}

}

.projects-tizer-box {
	position:relative;
	background:#F7F7F8;
	background:linear-gradient(to right, #F7F7F7 0%, #FBFBFC 100%);
	padding:120px 0 0;
	@media #{$media-xs}{
		padding-top:40px;
	}

	.scrolled-area-box{
		overflow:hidden;
		width:100vw;
		height:180vw;
		@media #{$media-sm}{
			height:250vw;
		}

		@media #{$media-xs}{
			height:auto;
			width:100%;
		}
		.scroller-y{
			width:100%;
			height:100%;
			position:relative;
			@media #{$media-xs}{
				height:auto;
			}
		}
		.scrolled-x{
			width:180vw;
			position:relative;
			@media #{$media-sm}{
				width:250vw;
			}
			@media #{$media-xs}{
				width:100%;
			}
		}
		.scrolled-screen-cent {
			display:flex;
			flex-direction:column;
			flex-wrap:nowrap;
			justify-content:center;
			align-items:stretch;
			position:relative;
			height:100vh;
			padding-top:8%;
			padding-bottom:3%;
			@media #{$media-xs}{
				padding: 0;
				height:auto;
			}
		}
	}
	.scrolled-text {
		position:absolute;
		bottom:8vh;
		left:5%;
		color:#232323;
		font-weight:500;
		font-size:16vw;
		line-height:20vw;
		background: linear-gradient(to right, #fff 0%, #F0F0F0 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		@media #{$media-xs}{
			position:static;
		}
		i{
			font-weight:300;
			font-style:normal;
		}
		@media #{$media-xs}{
			display:none;
		}
	}
	.sect-top{
		display:flex;
		flex-direction:row;
		flex-wrap:nowrap;
		justify-content:space-between;
		align-items:flex-start;
		width:100vw;
		padding: 8vh 5% 0;
		position:absolute;
		left:0;
		top:5%;
		@media #{$media-sm}{
			padding-left:4%;
		}
		@media #{$media-xs}{
			position:static;
			display:block;
			padding: 0 6% 30px;
		}
		h2{
			font-weight:500;
			font-size:70px;
			line-height:66px;
			margin:0;
			@media #{$media-sm}{
				font-size:60px;
				line-height:55px;
			}
			@media #{$media-xs}{
				font-size:11vw;
				line-height:12vw;
				br{
					display:none !important;
				}
			}
		}
		.worktext {
			display:block;
			font-size:15px;
			color:#818181;
			line-height:21px;
			@media #{$media-sm}{
				text-align:right;
			}
		}
		.left-col{
			width:50%;
			@media #{$media-xs}{
				width:100%;
			}
		}
		.right-col{
			width:50%;
			padding-top:20px;
			@media #{$media-xs}{
				width:100%;
			}
		}
	}
	.last-projects-list{
		display:flex;
		flex-direction:row;
		flex-wrap:nowrap;
		justify-content:flex-start;
		align-items:center;
		width:100%;
		height: 100%;
		position:relative;
		z-index:3;
		padding-left:20vw;
		@media #{$media-sm}{
			padding-left:10vw;
		}
		@media #{$media-xs}{
			display:block;
			padding:0 6%;
		}
		.item {
			width:22vw;
			margin-right:4vw;
			border-radius:50%;
			max-width:400px;
			position:relative;
			@media #{$media-sm}{
				width:33vw;
				margin-right:6vw;
			}
			@media #{$media-xs}{
				margin:0 auto 30px;
				width:100%;
				max-width:250px;
			}
			&:before {
				@include after-box;
				position:absolute;
				left:0;
				top:15%;
				margin-top:15px;
				right:-4vw;
				height:1px;
				background:#E6424E;
				@media #{$media-sm}{
					right:-6vw;
				}
				@media #{$media-xs}{
					left:50%;
					top:0;
					bottom:-30px;
					width:1px;
					margin-top:0;
					height:auto;
				}
			}
			&:after {
				@include after-box;
				padding-bottom:100%;
				background:#ccc;
				border-radius:50%;
				position:relative;
				z-index:3;
				background:rgba(255, 255, 255, 0.86);
				@media #{$media-xs}{
					background:rgba(255, 255, 255, 0.92);
				}
			}

			&:first-child {
				&:before {
					left:50%;
					@media #{$media-xs}{
						top:15%;
					}
				}
			}
			&:last-child{
				&:before {
					right:50%;
					@media #{$media-xs}{
						bottom:auto;
						height:15%;
					}
				}
			}
			.item-holder{
				@include full-height-abs;
				overflow:hidden;
				display:flex;
				flex-direction:column;
				flex-wrap:nowrap;
				justify-content:flex-start;
				align-items:center;
				padding: 15% 10%;
				text-align:center;
				z-index:4;
				.num {
					border-radius:50%;
					background:$redColor;
					color:#fff;
					width:30px;
					height:30px;
					display:flex;
					flex-direction:row;
					flex-wrap:nowrap;
					justify-content:center;
					align-items:center;
					position:relative;
					z-index:4;
				}
				.bottom-box{
					flex:1;
					display:flex;
					flex-direction:column;
					flex-wrap:nowrap;
					justify-content:center;
					align-items:center;
				}
				.name {
					display:block;
					font-size:16px;
					line-height:21px;
					color:#000;
					font-weight:500;
					@media #{$media-md}{
						font-size:14px;
						line-height:18px;
					}
					@media #{$media-xs}{
						font-size:14px;
						line-height:18px;
					}
				}
				em {
					font-style:normal;
					font-size:12px;
					line-height:15px;
					display:block;
					margin-top:15px;
					color:#898989;
				}

			}
		}

	}

}

















