html.modal-open,
html.modal-open{
    overflow-x: hidden;
}
body.modal-open {
    position: relative;
    overflow: hidden;
}
.pop-block{
    display:none;
    position:absolute;

}
.consult-modal{
    &.show{
        .modal-content {
            opacity:1;
            transform:scale(1);
        }
        .modal-wrap{
            background: rgba(0, 0, 0, 0.3);
        }
    }
    .modal-wrap{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        background:none;
        transition:all 0.3s ease;
    }
    .close-area{
        @include full-height-abs;
    }
    .modal-content{
        padding: 110px 5% 95px;
        max-width:900px;
        width:70%;
        transform: scale(0.8);
        opacity: 0;
        transition: all 0.3s ease;
        position: relative;
        box-shadow: 0 0 30px rgb(0 0 0 / 20%);
        border-radius:20px;
        @media #{$media-sm}{
            width:90%;
        }
        @media #{$media-xs}{
            width:94%;
            padding: 8% 6%;
            border-radius:10px;
        }
    }
    .modal-holder{
        transition:all 0.3s ease;
    }
    .input-wrap{
        position:relative;
        label.error {
            position:absolute;
            left:5%;
            color:#ffffff;
            font-weight:normal;
            padding:0 10px;
            border-radius:5px;
            font-size:12px;
            top:-5px;
            background:$redColor;
        }
    }
    .modal-contact-form{
       .form-main-row{
           display:flex;
           flex-direction:row;
           flex-wrap:nowrap;
           justify-content:space-between;
           align-items:stretch;
           @media #{$media-xs}{
               display:block;
           }
           .contact-col{
               width:310px;
               @media #{$media-xs}{
                   width:100%;
                   margin-bottom:10px;
               }
               .consult-title {
                   display:block;
                   font-size:40px;
                   line-height:34px;
                   font-weight:bold;
                   margin-bottom:35px;
                   @media #{$media-xs}{
                       font-size:34px;
                       margin-bottom:10px;
                   }
               }
               .or{
                   display:block;
                   font-size:20px;
                   line-height:26px;
                   @media #{$media-xs}{
                       font-size:16px;
                       line-height:19px;
                       margin-bottom:10px;
                   }
               }
               .tel{
                   color:#000;
                   font-size:20px;
                   transition:all 0.3s ease;
                   @media #{$media-xs}{
                       font-weight:500;
                       font-size:18px;
                   }
                   &:hover{
                       color:#666;
                   }
               }
           }
           .form-inputs{
               flex:1;
           }
       }
        select {
            appearance: none;
            background-color: transparent;
            border: none;
            padding: 0 1em 0 0;
            margin: 0;
            width: 100%;
            font-family: inherit;
            font-size: inherit;
            cursor: inherit;
            line-height: inherit;
        }
        select::-ms-expand {
            display: none;
        }
        .select {
            width: 100%;
            border: 1px solid #D3D3D3;
            border-radius:10px;
            height:58px;
            padding: 0 8%;
            font-size:16px;
            cursor: pointer;
            line-height: 1.1;
            background:url(../images/@2x/select-galka.png) no-repeat #fff 90% 50%;
            background-size:21px auto;
            @media #{$media-xs}{
                font-size:15px;
                height:50px;
                padding: 0 6%;
                background-position:94% 50%;
                background-size:19px auto;
            }
            &:hover{
                border-color:#666;
            }
            &:after {
                @include after-box;
                width:10px;
                height:10px;
                background:#000000;
                position:absolute;
                right:10px;
                top:10px;
            }

        }
        .form-control {
            height:58px;
            border-radius:10px;
            box-shadow:none;
            outline:none;
            border:1px solid #D8D8D8;
            font-size:16px;
            font-weight:500;
            color:#000;
            padding: 0 8%;
            @media #{$media-xs}{
                height:50px;
                padding: 0 6%;
            }
            @include placeholder {
                font-weight:normal;
                color:#666;
            }

            &:focus {
                border-color:#666;
            }
            &.error{
                border-color:$redColor;
            }
        }
        ._g-red-btn{
            width:100%;
        }
        .form-info{
            padding: 0 8% 25px;
            @media #{$media-xs}{
                padding: 0 6% 20px;
            }
            p {
                font-size:12px;
                line-height:14px;
                color:#ababab;
                a {
                    color:#666666;
                    text-decoration:underline;
                    &:hover {
                        color:#000000;
                        text-decoration:none;
                    }
                }
            }
        }
    }
    .success-abs-box{
        @include full-height-abs;
        opacity:0;
        visibility:hidden;
        background:#fff;
        border-radius:20px;
        z-index:3;
        transition:all 0.3s ease;
        .centered{
            display:flex;
            flex-direction:column;
            flex-wrap:nowrap;
            justify-content:space-between;
            align-items:stretch;
            height:100%;
            padding: 90px 5% 60px;
        }
        .success-icon{
            position:relative;
            margin:0 auto 30px;
            @media #{$media-xs}{
                width:180px;
            }
           img{
               transition:all 0.3s ease;
               transform:scale(0.6);
               opacity:0;
               transition-delay: 0.2s;
           }
        }
        .success-title {
            display:block;
            color:$redColor;
            font-size:15px;
        }
        .success-subtitle {
            display:block;
            font-weight:500;
            color:#000000;
            font-size:28px;
            @media #{$media-xs}{
                line-height:30px;
            }
        }
        .galka{
            width: 130px;
            height: 130px;
            position:absolute;
            top:50%;
            left:50%;
            margin-left:-65px;
            margin-top:-65px;
            &:before {
                @include after-box;
                height: 12px;
                width: 0px;
                left: 50%;
                background: #E71E24;
                position: absolute;
                top:50%;
                border-radius:6px;
                margin-top:-15px;
                margin-left: -50px;
                transform: rotate(45deg);
                transform-origin: 0 100%;
                transition: all 0.3s ease;
                transition-delay: 0.3s;
            }
            &:after {
                @include after-box;
                height: 12px;
                border-radius:6px;
                width: 0px;
                left: 50%;
                background: #E71E24;
                position: absolute;
                top:50%;
                margin-left:-7px;
                margin-top:28px;
                transform: rotate(-45deg);
                transform-origin:0 100%;
                transition: all 0.3s ease;
                transition-delay: .5s;
            }
        }
    }
    .price-info {
        display:flex;
        flex-direction:row;
        flex-wrap:nowrap;
        justify-content:space-between;
        align-items:center;
        padding: 10px 8% 30px;
        @media #{$media-sm}{
            display:block;
        }
        @media #{$media-xs}{
            padding: 0 6% 15px;
            display:block;
        }
        .price-label{
            display:block;
            font-size:26px;
            font-weight:500;
            color:#E9E9E9;
        }
        .price{
            display:block;
            font-size:46px;
            font-weight:500;
            color:#E9E9E9;
            white-space: nowrap;
            @media #{$media-xs}{
                font-size:42px;
                line-height:42px;
            }
            &.changed{
                color:#000;
            }
        }
    }
    &.sended{
        .modal-holder {
            opacity:0;
            visibility:hidden;
        }
        .success-abs-box{
            opacity:1;
            visibility:visible;
            .success-icon {
                img{
                    transform:scale(1);
                    opacity:1;
                }
            }
            .galka{
                &:before {
                    width: 60px;
                }
                &:after {
                    width: 100px;
                }
            }
        }
    }
}


.about-modal{
    &.show{
        .modal-wrap{
            background:#2A2A2A;
        }
        .pic-col {
            opacity:1;
            transform:none;
        }
        .text-col{
            opacity:1;
        }
        .scrollbar-track{
            opacity:1;
        }
        p.size-l{
            opacity:1;
            transform:none;
        }
        h2{
            opacity:1;
            transform:none;
        }
        .other-text {
            opacity:1;
            transform:none;
        }
        hr{
            width:100%;
            opacity:1;
        }
    }
    .modal-wrap{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        background:none;
        transition:all 0.3s ease;
    }
    .close-area{
        @include full-height-abs;
    }
    .scroll-content{
        padding-right:30px;
    }
    .scrollbar-track{
        opacity:0;
        transition:all 0.3s ease;
    }
    .modal-content {
        padding:8% 0 5% 0;
        width:90%;
        margin:0 auto;
        height:100vh;
        overflow:hidden;
        max-width:1100px;
        position:relative;
        background:none;
        box-shadow:none;
        border:none;
        border-radius:0;
        color:#fff;
        display:flex;
        flex-direction:row;
        flex-wrap:nowrap;
        justify-content:space-between;
        align-items:stretch;
        @media #{$media-xs}{

        }
    }
    .global-close-btn{
        right:5%;
        top:5%;
        @media #{$media-xs}{
            right:9%;
        }
        &:before,
        &:after{
            background-color:#fff;
        }
    }

    .pic-col {
        width:40%;
        border-radius:7px;
        overflow:hidden;
        opacity:0;
        transform:translateX(20px);
        transition:all 1s ease;
        @media #{$media-xs}{
            display:none;
        }
        picture {
            display:block;
            margin-bottom:20px;
            width:100%;
            max-height:100%;
            img {
                border-radius:7px;

                width:100%;
                object-fit:cover;
            }
        }

        .role {
            display:block;
            font-size:11px;
            line-height:14px;
            color:#6a6a6a;
        }
    }
    .text-holder{
        max-height:70vh;
        @media #{$media-xs}{
            max-height:90vh;
        }
    }
    .text-col {
        flex:1;
        padding-left:5%;
        transition:all 0.5s ease;
        opacity:0;
    }
    h2 {
        font-size:53px;
        line-height:42px;
        font-weight:500;
        margin:0 0 20px;
        transition:all 1s ease;
        transition-delay:0.2s;
        opacity:0;
        transform:translateY(10px);
        @media #{$media-xs}{
            font-size:44px;
            line-height:42px;
        }
    }
    p{
        font-size:14px;
        line-height:17px;
        margin:0 0 20px;
        &.size-l{
            font-size:22px;
            line-height:23px;
            margin:0 0 30px;
            opacity:0;
            transition:all 1s ease;
            transition-delay:0.4s;
            transform:translateY(20px);
            @media #{$media-xs}{
                font-size:18px;
                line-height:21px;
            }

        }
    }
    .other-text {
        opacity:0;
        transition:all 1s ease;
        transition-delay:1s;
    }
    hr{
        border-color:#444444;
        transition:all 0.5s ease;
        transition-delay:0.6s;
        width:0;
        opacity:0;
        margin-left:auto;
        margin-right:auto;
    }
}
